export interface FeatureFlags {
  // Sample feature flag keeps compiler warnings at bay when no other feature flags are active.
  sampleFeatureFlag: boolean;
  enableETicketOptionQRCode: boolean;
  enableETicketFraudDetection: boolean;
  enablePushNotifications: boolean;
  enableFieldResponseFootprintedModal: boolean;
  enableLeaveFootprintWithoutStub: boolean;
  enableReservationReceipt: boolean;
  enableSeatReservation: boolean;
  enableInstantWin: boolean;
  enableNumberInputGuestType: boolean;
  enableAccessTokenExpiredRedirect: boolean;
  enableETicketCheckinEndTime: boolean;
  enableNewExpirationTimePane: boolean;
  enableGmoPaymentGateway: boolean;
  enableSeatManagementMultiResource: boolean;
  enableETicketRedemptionCount: boolean;
  enableEquipmentInstanceMemo: boolean;
  enableAddressForm: boolean;
  enablePartnership: boolean;
  enableSpecialOfferPopupRevamp: boolean;
  enableReservationCheckinStatus: boolean;
  enableMemberLimitedProduct: boolean;
  enableSearchReservationsByAccessToken: boolean;
  enableRequestedPickupLocation: boolean;
  enableMobileOrder: boolean;
  enableUnitDisplayOrder: boolean;
  enablePartnershipPackageReservation: boolean;
  enableETicketPresetRedemptionCount: boolean;
  enableGoogleWalletPass: boolean;
  enableMarketingAutomationWebPush: boolean;
  enableMarketingAutomationTriggeredCampaign: boolean;
  enableMarketingAutomationGuidance: boolean;
  enableSingleCategorySelect: boolean;
  enableAddGoogleCalendar: boolean;
  enableMapPinDetailsFloatingActionButtons: boolean;
  enableMapGetDirections: boolean;
  enableWaitTimeCategory: boolean;
  enableETicketWebPushNotification: boolean;
  enableMapImagePreloading: boolean;
  enableNpsSurvey: boolean;
  enableWaitTimeForecast: boolean;
}

// In "development" mode, all features including those currently under development are enabled.
export const developmentFeatureFlags: FeatureFlags = {
  sampleFeatureFlag: true,
  enableETicketOptionQRCode: true,
  enableETicketFraudDetection: true,
  enablePushNotifications: true,
  enableFieldResponseFootprintedModal: true,
  enableLeaveFootprintWithoutStub: true,
  enableReservationReceipt: true,
  enableSeatReservation: true,
  enableInstantWin: true,
  enableNumberInputGuestType: true,
  enableAccessTokenExpiredRedirect: true,
  enableETicketCheckinEndTime: true,
  enableNewExpirationTimePane: true,
  enableGmoPaymentGateway: true,
  enableSeatManagementMultiResource: true,
  enableETicketRedemptionCount: true,
  enableEquipmentInstanceMemo: true,
  enableAddressForm: true,
  enablePartnership: true,
  enableSpecialOfferPopupRevamp: true,
  enableReservationCheckinStatus: true,
  enableMemberLimitedProduct: true,
  enableSearchReservationsByAccessToken: true,
  enableRequestedPickupLocation: true,
  enableMobileOrder: true,
  enableUnitDisplayOrder: true,
  enablePartnershipPackageReservation: true,
  enableETicketPresetRedemptionCount: true,
  enableGoogleWalletPass: true,
  enableMarketingAutomationWebPush: true,
  enableMarketingAutomationTriggeredCampaign: true,
  enableMarketingAutomationGuidance: true,
  enableSingleCategorySelect: true,
  enableAddGoogleCalendar: true,
  enableMapPinDetailsFloatingActionButtons: true,
  enableMapGetDirections: true,
  enableWaitTimeCategory: true,
  enableETicketWebPushNotification: true,
  enableMapImagePreloading: true,
  enableNpsSurvey: true,
  enableWaitTimeForecast: true,
};

// In "release" mode, only features ready for production release are enabled.
export const releaseFeatureFlags: FeatureFlags = {
  sampleFeatureFlag: false,
  enableETicketOptionQRCode: false,
  enableETicketFraudDetection: false,
  enablePushNotifications: false,
  enableFieldResponseFootprintedModal: true,
  enableLeaveFootprintWithoutStub: false,
  enableReservationReceipt: true,
  enableSeatReservation: false,
  enableInstantWin: false,
  enableNumberInputGuestType: true,
  enableAccessTokenExpiredRedirect: true,
  enableETicketCheckinEndTime: true,
  enableNewExpirationTimePane: true,
  enableGmoPaymentGateway: true,
  enableSeatManagementMultiResource: false,
  enableETicketRedemptionCount: true,
  enableEquipmentInstanceMemo: true,
  enableAddressForm: true,
  enablePartnership: true,
  enableSpecialOfferPopupRevamp: true,
  enableReservationCheckinStatus: true,
  enableMemberLimitedProduct: false,
  enableSearchReservationsByAccessToken: true,
  enableRequestedPickupLocation: true,
  enableMobileOrder: false,
  enableUnitDisplayOrder: true,
  enablePartnershipPackageReservation: false,
  enableETicketPresetRedemptionCount: true,
  enableGoogleWalletPass: false,
  enableMarketingAutomationWebPush: false,
  enableMarketingAutomationTriggeredCampaign: false,
  enableMarketingAutomationGuidance: false,
  enableSingleCategorySelect: true,
  enableAddGoogleCalendar: true,
  enableMapPinDetailsFloatingActionButtons: true,
  enableMapGetDirections: false,
  enableWaitTimeCategory: false,
  enableETicketWebPushNotification: false,
  enableMapImagePreloading: false,
  enableNpsSurvey: false,
  enableWaitTimeForecast: false,
};

// stg feature flags are not ready for release but are enabled for demo purposes in the stg environment.
// This breaks our "production is the same as staging" testing so it should be used sparingly.
export const stgDemoFeatureFlags: Partial<FeatureFlags> = {
  enableETicketOptionQRCode: true,
  enablePushNotifications: true,
  enableFieldResponseFootprintedModal: true,
  enableLeaveFootprintWithoutStub: true,
  enableSeatReservation: true,
  enableReservationReceipt: true,
  enableInstantWin: true,
  enableNumberInputGuestType: true,
  enableETicketCheckinEndTime: true,
  enableNewExpirationTimePane: true,
  enableGmoPaymentGateway: true,
  enableSeatManagementMultiResource: true,
  enableETicketRedemptionCount: true,
  enableEquipmentInstanceMemo: true,
  enableAddressForm: true,
  enablePartnership: true,
  enableSpecialOfferPopupRevamp: true,
  enableRequestedPickupLocation: true,
  enableMobileOrder: true,
  enablePartnershipPackageReservation: true,
  enableETicketPresetRedemptionCount: true,
  enableMarketingAutomationWebPush: true,
  enableMarketingAutomationTriggeredCampaign: true,
  enableMarketingAutomationGuidance: true,
  enableSingleCategorySelect: true,
  enableMapGetDirections: true,
  enableWaitTimeCategory: true,
  enableMapImagePreloading: true,
  enableWaitTimeForecast: true,
};
